// @ts-ignore
import { getUrlWithTrailingSlash } from "~/utils/getUrlWithTrailingSlash";
import { MetaInfo } from "~/types";

export function getPageMetaConfig({
  page,
  strapiUrl,
  routePath,
}): MetaInfo | {} {
  const { metadata } = page.attributes;

  return {
    title: metadata.metaTitle,
    meta: [
      {
        key: "description",
        name: "description",
        content: metadata.metaDescription || "",
      },
      { key: "og:type", property: "og:type", content: "website" },
      { key: "og:title", property: "og:title", content: metadata.metaTitle },
      {
        key: "og:description",
        property: "og:description",
        content: metadata.metaDescription || "",
      },
      {
        key: "og:url",
        property: "og:url",
        content: `https://www.gentwo.com${getUrlWithTrailingSlash(routePath)}`,
      },
      {
        key: "og:image",
        property: "og:image",
        content: metadata?.shareImage?.data?.attributes?.url
          ? strapiUrl + metadata?.shareImage?.data?.attributes?.url
          : "",
      },
      {
        key: "og:image:type",
        property: "og:image:type",
        content: metadata.shareImage?.data?.attributes?.mime || "",
      },
      {
        key: "og:image:alt",
        property: "og:image:alt",
        content: metadata.shareImage?.data?.attributes?.alternativeText || "",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://www.gentwo.com" + getUrlWithTrailingSlash(routePath),
      },
    ],
  };
}

/**
 * Custom error type
 */
export type MyCustomError = {
  name: string;
  message: string;
};

/**
 * Create a custom error
 * @param name - The name of the error
 * @param message - The error message
 * @returns The custom error object
 */
export function createError(name: string, message: string): MyCustomError {
  return { name, message };
}

/**
 * Error handling utility
 * @param error - The error to handle
 */
export function handleError(error: MyCustomError): void {
  // Custom error handling logic
  // eslint-disable-next-line no-console
  console.error("An error occurred:", error);

  // Optionally, perform additional actions based on the error type
}
